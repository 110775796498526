import React, { createContext, useState, useContext, useEffect } from "react";

const StateManageContext = createContext();

const GlobalStateContextProvider = ({ children }) => {
  const [sidenav, setSideNav] = useState("bank");


  const contextValue = {
    sidenav, setSideNav
  };

  return (
    <StateManageContext.Provider value={contextValue}>
      {children}
    </StateManageContext.Provider>
  );
};

const GlobalState = () => {
  const context = useContext(StateManageContext);
  if (!context) {
    throw new Error(
      "useManageState must be used within a StateContextProvider"
    );
  }
  return context;
};

export { GlobalState, GlobalStateContextProvider };
